import { Page } from '../components/Page'

const Page404: React.FC = () => {
  return (
    <Page pathname="404" pageTitle="404">
      <div
        style={{
          backgroundColor: 'black',
          height: 'var(--vh)',
          position: 'relative',
        }}
      >
        <h1 style={{ position: 'relative', top: '-40px' }}>Meme Not Found</h1>
        <p>There Is No Hope</p>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            overflow: 'hidden',
            opacity: 0.0404,
            pointerEvents: 'none',
            textAlign: 'justify',
            fontSize: '40.4px',
          }}
        >
          {Array<'404'>(404)
            .fill('404', 0, 404)
            .map(q0q => q0q)}
        </div>
      </div>
    </Page>
  )
}

export default Page404
